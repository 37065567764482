import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { Box, BoxProps, Flex, HStack, LinkProps, VStack } from '@chakra-ui/react';
import { SpotOnLogoLink } from '@atoms/SpotOnLogoLink';
import { Copyright } from '@olo-web/components/atoms/Copyright';
import { FooterLink } from '@olo-web/components/atoms/FooterLink';
import { ELegalLinks } from '@olo-web/types/enums';
import { useScreenSizeState } from '@olo-web/client-state';
import { RecaptchaTerms } from '@olo-web/components/atoms/RecaptchaTerms';

const commonLinkStyles: LinkProps = {
  fontSize: { base: 'xs', md: 'sm' },
  textAlign: 'center',
};

const FooterRenderer: ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (props, ref) => {
  const { isSmDown } = useScreenSizeState();

  return (
    <Box as="footer" h="auto" maxW="100%" mt={20} bg="base.50" ref={ref} {...props}>
      <VStack
        spacing={4}
        mx={{ base: 3, sm: 10 }}
        py={6}
        bg="base.50"
        borderTop="1px solid rgba(0, 0, 0, 0.12)"
      >
        <Flex
          justify="space-between"
          direction={{ base: 'column', sm: 'row' }}
          width="100%"
          align="center"
        >
          {!isSmDown && <SpotOnLogoLink />}
          <Flex align="center" direction={{ base: 'column', sm: 'row' }}>
            <HStack
              as="ul"
              align="center"
              justify="center"
              flexWrap="wrap"
              spacing={{ base: 4, sm: 6 }}
              listStyleType="none"
            >
              <FooterLink
                linkStyles={commonLinkStyles}
                linkText="Privacy Policy"
                linkHref={ELegalLinks.PRIVACY_POLICY}
              />
              <FooterLink
                linkStyles={commonLinkStyles}
                linkText="Terms of Use"
                linkHref={ELegalLinks.TERMS_OF_USE}
              />
              <FooterLink
                linkStyles={commonLinkStyles}
                linkText="Accessibility Statement"
                linkHref={ELegalLinks.ACCESSIBILITY_STATEMENT}
              />
            </HStack>
            {isSmDown && <SpotOnLogoLink />}
            <Box ml={{ base: 0, sm: 8 }}>
              <Copyright />
            </Box>
          </Flex>
        </Flex>

        <Box width="100%" mt={4}>
          <RecaptchaTerms />
        </Box>
      </VStack>
    </Box>
  );
};

export const Footer = forwardRef(FooterRenderer);
