import React from 'react';
import { Icon, Link } from '@chakra-ui/react';
import { SpotOnLogoHorizontal } from '@olo-web/assets/images/SpotOnLogoHorizontal.ui';
export const SpotOnLogoLink = () => {
  return (
    <Link href="https://spoton.com" target="_blank" rel="noreferrer" aria-label="Powered by SpotOn">
      <Icon
        as={SpotOnLogoHorizontal}
        marginTop="24px"
        marginBottom="16px"
        w="142px"
        h="24px"
        opacity="0.54"
      />
    </Link>
  );
};
