import React from 'react';
import { Link, LinkProps, Flex } from '@chakra-ui/react';

interface ILinkProps {
  linkStyles?: LinkProps;
  linkText: string;
  linkHref: string;
}

export const FooterLink = ({ linkStyles, linkText, linkHref }: ILinkProps) => {
  return (
    <Flex as="li">
      <Link href={linkHref} color="blackAlpha.400" textDecor="underline" isExternal {...linkStyles}>
        {linkText}
      </Link>
    </Flex>
  );
};
