import React from 'react';
import { Flex, Text, LinkProps } from '@chakra-ui/react';
import { FooterLink } from '../FooterLink';
import { ELegalLinks } from '@olo-web/types/enums';

const commonLinkStyles: LinkProps = {
  textAlign: 'center',
  mx: 1,
  color: 'blackAlpha.400',
  fontSize: { base: 'xs', md: 'sm' },
};

export const RecaptchaTerms = () => (
  <Flex
    width="100%"
    justifyContent="center"
    alignItems="center"
    flexWrap={{ base: 'wrap', sm: 'nowrap' }}
    px={2}
    fontSize={{ base: 'xs', md: 'sm' }}
  >
    <Text
      as="span"
      color="blackAlpha.400"
      textAlign="center"
      whiteSpace={{ base: 'normal', sm: 'nowrap' }}
    >
      This site is protected by reCAPTCHA and the Google
    </Text>
    <FooterLink
      linkStyles={commonLinkStyles}
      linkText="Privacy Policy"
      linkHref={ELegalLinks.RECAPTCHA_PRIVACY_POLICY}
    />
    <Text as="span">and</Text>
    <FooterLink
      linkStyles={commonLinkStyles}
      linkText="Terms of Service"
      linkHref={ELegalLinks.RECAPTCHA_TERMS_OF_SERVICE}
    />
    <Text as="span">apply.</Text>
  </Flex>
);
